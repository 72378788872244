import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Telephone } from 'react-bootstrap-icons';
import '../Style/MyNavbar.css'

const MyNavbar = () => {
  return (
    <Navbar bg="light" sticky="top">
      <Container>
        <Navbar.Brand as={Link} to="/">
          Brothers Carpentry
        </Navbar.Brand>
        <Nav className="ms-auto">
          <Nav.Link href="tel:+018322446809" className="d-flex align-items-center">
            <Telephone size={18} className="me-2" />+1 (832) 244-6809
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;
