import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Facebook, Instagram, Google, House, Envelope, Phone } from 'react-bootstrap-icons';

const MyFooter = () => {
  // Operating hours Between 8AM and 8 PM CST
  return (
    <footer className="text-white bg-dark">
      <div className="container p-4">
        <div className="row">
          {/* About Us Section */}
          <div className="col-lg-5 col-md-12 mb-4 mb-md-0">
            <h5 className="text-uppercase mb-4">Our Mission</h5>
            <p>
              We’re dedicated to making our customers happy with honest pricing. If you can dream it, we can build it. All work is warrantied for one year, and all applicable manufacturer warranties are included.
            </p>
            <p>
              We strive for clean, efficient, and high-quality work with open communication and attention to detail.
            </p>
            <Row className="">
              <Col xs="auto">
                <a href="https://www.facebook.com/profile.php?id=61563023883181" target="_blank" rel="noopener noreferrer">
                <Facebook size={32} className="social-icon me-1" />
                </a>
              </Col>
              <Col xs="auto">
                <a href="https://www.instagram.com/brothers_carpentry_llc/?hl=en" target="_blank" rel="noopener noreferrer">
                <Instagram size={32} className="social-icon me-1" />
                </a>
              </Col>
              <Col xs="auto">
                <a href="https://g.page/r/CRy-h0yF_84iEAE/review" target="_blank" rel="noopener noreferrer">
                <Google size={32} className="social-icon" />
                </a>
              </Col>
            </Row>
          </div>

          {/* Contact Us Section */}
          <div className="col-lg-5 col-md-12 mb-4 mb-md-0 ms-auto">
            <h5 className="text-uppercase mb-4">Contact Us</h5>
            <ul className="list-unstyled">
              <li className="d-flex align-items-center mb-3">
                <House size={18} className="me-2" /> Houston, TX 77386, US
              </li>
              <li className="d-flex align-items-center mb-3">
                <Envelope size={18} className="me-2" /> carpentrybrosdw@gmail.com
              </li>
              <li className="d-flex align-items-center mb-3">
                <Phone size={18} className="me-2" /> + 01 (832) 378-8017
              </li>
              <li className="d-flex align-items-center mb-3">
                <Phone size={18} className="me-2" /> + 01 (832) 244-6809
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="text-center p-3 bg-dark text-light">
        © 2024 Brothers Carpentry and Handyman Services LLC
      </div>
    </footer>
  );
}

export default MyFooter;
