import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import '../Style/ServiceDetail.css';
import BackButton from './BackButton';
import SocialMedia from './SocialMedia';
import BusinessLocationMap from './BuisnessLocationMap';
import QuoteButton from './QuoteButton';

const services = [
  { title: "Decks, Fences, Gazebos, Pergolas, Kitchenettes & more", link: "/services/decks-fences-gazebos", description: "We offer a range of services including building decks, fences, gazebos, pergolas, and kitchenettes. Our work is tailored to meet your specific needs and preferences.", image: require('../images/projects/proj13/out6.jpg') },
  { title: "Siding - Hardie, Vinyl, Aluminum, Stucco, & more", link: "/services/siding", description: "We provide expert siding installation and repair, including options like Hardie, vinyl, aluminum, and stucco to enhance the appearance and durability of your home.", image: require('../images/projects/proj3/IMG_2365.jpg') },
  { title: "Remodeling - Bathrooms, Kitchens, Closets, & more", link: "/services/remodeling", description: "Transform your living space with our remodeling services. We handle bathroom, kitchen, closet remodels, and more to create the perfect space for you.", image: require('../images/projects/proj1/IMG_1516.jpg') },
  { title: "Painting Exterior & Interior", link: "/services/painting", description: "Our painting services cover both exterior and interior needs. We use high-quality paints and techniques to ensure a flawless finish.", image: require('../images/projects/proj12/IMG_3368.jpg') },
  { title: "Drywall Repair & Replacement", link: "/services/drywall-repair", description: "We offer comprehensive drywall repair and replacement services to restore your walls to their original condition or update them with new installations.", image: require('../images/projects/proj7/IMG_0438.jpg') },
  { title: "Home Additions", link: "/services/home-additions", description: "Expand your home with our addition services. We can help you add extra rooms, expand existing spaces, or create entirely new areas.", image: require('../images/projects/proj10/IMG_3194.jpg') },
  { title: "Flooring & Tile", link: "/services/flooring-tile", description: "Upgrade your flooring with our wide selection of options. We handle everything from tile installation to new flooring solutions.", image: require('../images/projects/proj6/IMG_1598.jpg') },
  { title: "Roofs", link: "/services/roofs", description: "Ensure your home is protected with our roofing services. We offer repairs, replacements, and new installations to keep your roof in top condition.", image: require('../images/projects/proj4/IMG_1708.jpg') },
  { title: "Concrete", link: "/services/concrete", description: "We provide concrete services including installation and repair for driveways, sidewalks, and other concrete surfaces.", image: require('../images/projects/proj9/IMG_3859.jpg') },
  { title: "Pressure Washing", link: "/services/pressure-washing", description: "Revitalize your home’s exterior with our pressure washing services. We remove dirt, grime, and other buildup to restore your surfaces.", image: require('../images/Brothers-carpentry-photos/deck3.jpg') },
  { title: "Custom Metal Fabrication & Welding", link: "/services/metal-fabrication", description: "Our custom metal fabrication and welding services offer bespoke solutions for your needs, from unique metal designs to functional welding projects.", image: require('../images/projects/IMG_1263.jpg') }
];

const preloadImages = (images) => {
  images.forEach(image => {
    const img = new Image();
    img.src = image;
  });
};

const ServiceDetail = () => {
  const { serviceName } = useParams();
  const service = services.find(s => s.link === `/services/${serviceName}`);
  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Preload images
    const imageUrls = services.map(s => s.image);
    preloadImages(imageUrls);
  }, []);

  if (!service) {
    return <div>Service not found.</div>;
  }

  return (
    <>
      <BackButton />
      <div className="service-detail-wrapper">
        {isMobile ? (
          <DropdownButton
            id="service-dropdown"
            title="Select a Service"
            className="service-dropdown"
          >
            {services.map((s) => (
              <Dropdown.Item
                as={Link}
                to={s.link}
                key={s.link}
                className={`service-nav-link ${s.link === service.link ? 'active' : ''}`}
              >
                {s.title}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        ) : (
          <nav className="service-nav">
            <h3>Our Services</h3>
            <ul>
              {services.map((s) => (
                <li key={s.link}>
                  <Link 
                    to={s.link} 
                    className={`service-nav-link ${s.link === service.link ? 'active' : ''}`}
                  >
                    {s.title}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        )}
        <div className="service-detail-content">
          <h1>{service.title}</h1>
          <img
            className="service-image"
            src={service.image}
            alt={service.title}
            loading="lazy" // Enable lazy loading
          />
          <p>{service.description}</p>
        </div>
      </div>
      <SocialMedia />
    </>
  );
};

export default ServiceDetail;
