import React, { useState } from 'react';
import { Container, Row, Col, Card, Modal, Carousel } from 'react-bootstrap';
import BusinessLocationMap from '../Components/BuisnessLocationMap';
import QuoteButton from '../Components/QuoteButton';

// proj8
import woodlandsfence1 from '../images/projects/proj8/IMG_3838.jpg'
import woodlandsfence2 from '../images/projects/proj8/IMG_3856.jpg'
import woodlandsfence3 from '../images/projects/proj8/IMG_3893.jpg'
import woodlandsfence4 from '../images/projects/proj8/IMG_3899.jpg'
import woodlandsfence5 from '../images/projects/proj8/IMG_3906.jpg'
import woodlandsfence6 from '../images/projects/proj8/IMG_3942.jpg'
import woodlandsfence7 from '../images/projects/proj8/IMG_3944.jpg'
import woodlandsfence8 from '../images/projects/proj8/IMG_3945.jpg'
import woodlandsfence9 from '../images/projects/proj8/IMG_3948.jpg'
// prog10
import fence1 from '../images/projects/proj10/012fd2288ebbdd58ac9b99cabd9828fce1804bbbf4.jpg'
import fence2 from '../images/projects/proj10/IMG_0942.jpg'
import fence3 from '../images/projects/proj10/IMG_1283.jpg'
import fence4 from '../images/projects/proj10/IMG_2805.jpg'
import fence5 from '../images/projects/proj10/IMG_2806.jpg'
import fence6 from '../images/projects/proj10/IMG_3096.jpg'
import fence7 from '../images/projects/proj10/IMG_3097.jpg'
import fence8 from '../images/projects/proj10/IMG_3100.jpg'
import fence9 from '../images/projects/proj10/IMG_3191.jpg'
import fence10 from '../images/projects/proj10/IMG_3192.jpg'
import fence11 from '../images/projects/proj10/IMG_3193.jpg'
import fence12 from '../images/projects/proj10/IMG_3194.jpg'
import fence13 from '../images/projects/proj10/IMG_3214.jpg'
// proj11
import fencetwo1 from '../images/projects/proj11/IMG_3518.jpg'
import fencetwo2 from '../images/projects/proj11/IMG_3522.jpg'
import fencetwo3 from '../images/projects/proj11/IMG_3528.jpg'
import fencetwo4 from '../images/projects/proj11/IMG_3529.jpg'
import fencetwo5 from '../images/projects/proj11/IMG_3531.jpg'
// proj1
import proj41 from '../images/projects/proj1/IMG_1514.jpg'
import proj42 from '../images/projects/proj1/IMG_1515.jpg'
import proj43 from '../images/projects/proj1/IMG_1516.jpg'
import proj44 from '../images/projects/proj1/IMG_1531.jpg'
// proj2
import proj51 from '../images/projects/proj2/IMG_0349.jpg'
import proj52 from '../images/projects/proj2/IMG_0354.jpg'
// proj3
import proj61 from '../images/projects/proj3/IMG_0138.jpg'
import proj62 from '../images/projects/proj3/IMG_0139.jpg'
import proj63 from '../images/projects/proj3/IMG_2309.jpg'
import proj64 from '../images/projects/proj3/IMG_2362.jpg'
import proj65 from '../images/projects/proj3/IMG_2364.jpg'
import proj66 from '../images/projects/proj3/IMG_2365.jpg'
// proj4
import projfo1 from '../images/projects/proj4/IMG_1525.jpg'
import projfo2 from '../images/projects/proj4/IMG_1529.jpg'
import projfo3 from '../images/projects/proj4/IMG_1707.jpg'
import projfo4 from '../images/projects/proj4/IMG_1708.jpg'
import projfo5 from '../images/projects/proj4/IMG_1712.jpg'
import projfo6 from '../images/projects/proj4/IMG_3730.jpg'
// proj 5
import project51 from '../images/projects/proj5/IMG_2213.jpg'
import project52 from '../images/projects/proj5/IMG_2217.jpg'
// proj 6
import project61 from '../images/projects/proj6/IMG_1598.jpg'
import project62 from '../images/projects/proj6/IMG_2407.jpg'
// proj 7
import proj71 from '../images/projects/proj7/IMG_0421.jpg'
import proj72 from '../images/projects/proj7/IMG_0437.jpg'
import proj73 from '../images/projects/proj7/IMG_0438.jpg'
// proj9
import proj91 from '../images/projects/proj9/IMG_3756.jpg'
import proj92 from '../images/projects/proj9/IMG_3759.jpg'
import proj93 from '../images/projects/proj9/IMG_3764.jpg'
import proj94 from '../images/projects/proj9/IMG_3765.jpg'
import proj95 from '../images/projects/proj9/IMG_3766.jpg'
import proj96 from '../images/projects/proj9/IMG_3859.jpg'
// proj12
import proj121 from '../images/projects/proj12/IMG_3366.jpg'
import proj122 from '../images/projects/proj12/IMG_3368.jpg'
import proj123 from '../images/projects/proj12/IMG_3369.jpg'
// proj13
import proj131 from '../images/projects/proj13/out1.jpg'
import proj132 from '../images/projects/proj13/out2.jpg'
import proj133 from '../images/projects/proj13/out3.jpg'
import proj134 from '../images/projects/proj13/out4.jpg'
import proj135 from '../images/projects/proj13/out5.jpg'
import proj136 from '../images/projects/proj13/out6.jpg'

import '../Style/ProjectsPage.css';
import SocialMedia from '../Components/SocialMedia';
import BackButton from '../Components/BackButton';
//  tile job towards top, storm damage repair, woodlands fence
const projects = [
  {
    title: "Woodlands Fence Installation",
    description: "This 135-foot shadow box-style fence, installed in The Woodlands, features 1.5-inch spacing, double rot boards, trim, and a cedar top cap. The posts were securely set 3 feet deep, as specified by the client, ensuring a successful installation and customer satisfaction.",
    images: [woodlandsfence9, woodlandsfence3, woodlandsfence4, woodlandsfence5, woodlandsfence6, woodlandsfence7, woodlandsfence8]
  },
  {
    title: "Tile Installation",
    description: "We installed over 800 square feet of porcelain tile planks, including the necessary jacking up of the exterior wall and reframing of the subfloor to support the added weight of the materials.",
    images: [project61, project62]
  },
  {
    title: "Upper Deck with Lower Kitchenette",
    description: "This deck replacement project in The Woodlands included composite decking on a roof with a drainage system using EPDM rubber roofing. The design directed water into gutters and featured a fully finished kitchenette with can lights, ceiling fans, and a soffit ceiling.",
    images: [proj131, proj132, proj133, proj134, proj135, proj136]
  },
  {
    title: "Multiple Fence Replacement and Repair Projects",
    description: "We provide a wide range of wood fencing solutions, from repairs to complete installations, tailored to meet the specific needs and preferences of our clients.",
    images: [fence11, fence2, fence3, fence4, fence5, fence6, fence7, fence8, fence9, fence10, fence1, fence12, fence13]
  },
  {
    title: "Brighton Academy Fence Installation",
    description: "This project involved the installation of an 8-foot double picket, double rot board, pressure-treated pine fence with four rails at Brighton Academy, designed to enhance durability and aesthetics.",
    images: [fencetwo5, fencetwo4, fencetwo3, fencetwo2, fencetwo1, woodlandsfence1, woodlandsfence2]
  },
  {
    title: "Residential Home Remodeling",
    description: "In this project, we replaced the carpeted flooring with wooden flooring to match the existing decor. Additionally, we constructed an office with an arched doorway, seamlessly integrating it into the home's overall design.",
    images: [proj41, proj42, proj43, proj44]
  },
  {
    title: "Water Heater Encasement",
    description: "This small commercial project involved encasing the water heater to ensure compliance with local building codes.",
    images: [proj51, proj52]
  },
  {
    title: "Complete Wall Replacement",
    description: "This project required the complete replacement of the framing, siding, and exterior wall to restore the structural integrity and appearance of the building.",
    images: [proj61, proj62, proj63, proj64, proj65, proj66]
  },
  {
    title: "Storm Damage Repair",
    description: "We repaired storm damage by replacing 12 or more roof rafters and re-roofing the house to meet insurance claim requirements.",
    images: [projfo1, projfo2, projfo3, projfo4, projfo5, projfo6]
  },
  {
    title: "Small Deck Repair",
    description: "This project involved repairing a small deck with the addition of a cedar handrail and top cap, along with Trex composite decking for enhanced durability.",
    images: [project51, project52]
  },
  {
    title: "Good Neighbor Fence Installation",
    description: "Installed a 'Good Neighbor' style fence, ensuring an aesthetically pleasing appearance from both sides of the property.",
    images: [proj91, proj92, proj93, proj94, proj95, proj96]
  },
  {
    title: "Drywall Repair and Painting",
    description: "After a shower leak caused significant drywall damage, we promptly replaced and repaired the affected area. We applied a stomp-style texture and a flat white color match to blend seamlessly with the existing ceiling.",
    images: [proj121, proj122, proj123]
  }
];

const ProjectsPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const handleClose = () => setShowModal(false);
  const handleShow = (project) => {
    setSelectedProject(project);
    setShowModal(true);
  };

  return (
    <Container className="my-5">
      <BackButton />
      <h1 className="text-center mb-5">Our Projects</h1>
      <Row>
        {projects.map((project, index) => (
          <Col md={4} className="mb-4" key={index}>
            <Card className="project-card border" onClick={() => handleShow(project)}>
              <Card.Img variant="top" src={project.images[0]} className="card-img-top" />
              <Card.Body>
                <Card.Title>{project.title}</Card.Title>
                <Card.Text>
                  {project.description}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Modal */}
      {selectedProject && (
        <Modal show={showModal} onHide={handleClose} centered size="xl" className="custom-fullscreen-modal">
          <Modal.Header closeButton>
            <Modal.Title>{selectedProject.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Carousel>
              {selectedProject.images.map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100 modal-img"
                    src={image}
                    alt={`Slide ${index + 1}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
            <p className="mt-3">{selectedProject.description}</p>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={handleClose}>Close</button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Social Banner */}
      <SocialMedia />

      {/* Map */}
      <BusinessLocationMap />

      {/* Get a Free Quote */}
      <QuoteButton />

    </Container>
  );
}

export default ProjectsPage;
