import React from 'react';
import { Container, Image } from 'react-bootstrap';
import mapImage from '../images/Brothers-carpentry-photos/map_location.png';

const BusinessLocationMap = () => {
  return (
    <Container className="text-center my-5 rounded">
      <h2>Located in Houston, TX</h2>
      <Image src={mapImage} alt="Map showing our service area" fluid />
    </Container>
  );
};

export default BusinessLocationMap;
