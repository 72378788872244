import React from 'react';
import { Row, Col, Card, Carousel } from 'react-bootstrap';
import { Google } from 'react-bootstrap-icons';
import '../Style/TestamonialsStyle.css';

const Testimonials = () => {
  return (
    <Row className="mb-4 py-5 justify-content-center text-center">
      <Col lg={10}>
        <h2 className="text-center mb-5">What Our Clients Say</h2>
        <Carousel interval={5000} className="testimonial-carousel" controls={true} indicators={true}>
          <Carousel.Item>
            <Card className="testimonial-card mx-auto">
              <Card.Body>
                <Card.Text className="testimonial-text">
                  "Great service! Highly recommended."
                </Card.Text>
                <Card.Subtitle className="testimonial-author">
                  - Happy Customer
                </Card.Subtitle>
              </Card.Body>
            </Card>
          </Carousel.Item>
          <Carousel.Item>
            <Card className="testimonial-card mx-auto">
              <Card.Body>
                <Card.Text className="testimonial-text">
                  "Professional and affordable. Will use again!"
                </Card.Text>
                <Card.Subtitle className="testimonial-author">
                  - Satisfied Client
                </Card.Subtitle>
              </Card.Body>
            </Card>
          </Carousel.Item>
          <Carousel.Item>
            <Card className="testimonial-card mx-auto">
              <Card.Body>
                <Card.Text className="testimonial-text">
                  "Exceeded our expectations. Quality work!"
                </Card.Text>
                <Card.Subtitle className="testimonial-author">
                  - Another Happy Customer
                </Card.Subtitle>
              </Card.Body>
            </Card>
          </Carousel.Item>
        </Carousel>
        <p className="mt-4">
          <a 
            href="https://g.page/r/CRy-h0yF_84iEAE/review" 
            target="_blank" 
            rel="noopener noreferrer"
            className="review-link"
          >
            <Google className="google-icon" /> Leave us a review on Google
          </a>
        </p>
      </Col>
    </Row>
  );
}

export default Testimonials;
