// src/Components/AboutUs.js
import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import aboutImage from '../images/Brothers-carpentry-photos/brothers carpentry logo short.jpg';
import '../Style/AboutUs.css';

const AboutUs = () => {
  return (
    <Container className="my-5">
      <Row className="align-items-center text-center text-md-start">
        <Col md={6} className="mb-4 mb-md-0">
          <Image src={aboutImage} fluid rounded className="about-image" />
        </Col>
        <Col md={6}>
          <h2>About Us</h2>
          <p>
            At Brothers Carpentry, we are dedicated to providing exceptional carpentry and remodeling services.
            From decks and fences to complete home renovations, our team is equipped with the skills and experience
            to handle projects of all sizes. We operate in Houston Texas, serving both residential and commercial
            clients. Our commitment to quality craftsmanship and customer satisfaction sets us apart in the industry.
          </p>
          <p>
            We pride ourselves on being a local, family-owned business that values honesty and integrity. Whether you
            need a small repair or a major renovation, you can count on us to deliver outstanding results.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
