import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { House, Tools, Hammer, Brush, Gear, Building, Water, Shield } from 'react-bootstrap-icons';
import '../Style/OurServicesStyle.css';

const services = [
  { title: "Decks, Fences, Gazebos, Pergolas, Kitchenettes & more", icon: <House size={24} />, link: "/services/decks-fences-gazebos" },
  { title: "Siding - Hardie, Vinyl, Aluminum, Stucco, & more", icon: <Tools size={24} />, link: "/services/siding" },
  { title: "Remodeling - Bathrooms, Kitchens, Closets, & more", icon: <Hammer size={24} />, link: "/services/remodeling" },
  { title: "Painting Exterior & Interior", icon: <Brush size={24} />, link: "/services/painting" },
  { title: "Drywall Repair & Replacement", icon: <Gear size={24} />, link: "/services/drywall-repair" },
  { title: "Home Additions", icon: <Building size={24} />, link: "/services/home-additions" },
  { title: "Flooring & Tile", icon: <House size={24} />, link: "/services/flooring-tile" },
  { title: "Roofs", icon: <House size={24} />, link: "/services/roofs" },
  { title: "Concrete", icon: <Shield size={24} />, link: "/services/concrete" },
  { title: "Pressure Washing", icon: <Water size={24} />, link: "/services/pressure-washing" },
  { title: "Custom Metal Fabrication & Welding", icon: <Tools size={24} />, link: "/services/metal-fabrication" }
];

const OurServices = () => {
  return (
    <>
      <Row className="mb-4">
        <Col>
          <h2>Our Services</h2>
        </Col>
      </Row>
      <Row>
        {services.map((service, index) => (
          <Col md={4} className="mb-4" key={index}>
            <Card className="text-center service-card" onClick={() => window.location.href = service.link}>
              <Card.Body>
                <div className="mb-3">
                  {service.icon}
                </div>
                <Card.Title>{service.title}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default OurServices;
