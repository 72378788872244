import React from 'react';
import { Container } from 'react-bootstrap';
import QuoteButton from './QuoteButton';
import '../Style/Jumbotron.css';

const Jumbotron = () => {
  return (
    <Container 
      fluid 
      className="jumbotron-custom d-flex flex-column justify-content-center align-items-center text-center"
    >
      <h1 className="mb-4">Striving for Excellence in Every Project</h1>
      <p className="h4 mb-4">
        Honest - Affordable - Local
      </p>
      <QuoteButton />
    </Container>
  );
}

export default Jumbotron;
