import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Home from './Pages/HomePage';
import ServiceDetail from './Components/ServiceDetail';
import Projects from './Pages/ProjectsPage';
import ScrollToTop from './Components/ScrollToTop';
import MyFooter from './Components/MyFooter';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/services/:serviceName" element={<ServiceDetail />} />
        </Routes>
        <MyFooter />
      </div>
    </Router>
  );
}

export default App;
