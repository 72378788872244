import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Facebook, Instagram, Google } from 'react-bootstrap-icons';

const SocialMedia = () => {
  return (
    <Container className="my-5 text-center">
      <Row>
        <Col>
          <h2>Keep Up with Our Most Recent Work on Social Media</h2>
        </Col>
      </Row>
      <Row className="justify-content-center py-5">
        <Col xs="auto">
          <a href="https://www.facebook.com/profile.php?id=61563023883181" target="_blank" rel="noopener noreferrer">
            <Facebook size={50} className="social-icon me-3" />
          </a>
        </Col>
        <Col xs="auto">
          <a href="https://www.instagram.com/brothers_carpentry_llc/?hl=en" target="_blank" rel="noopener noreferrer">
            <Instagram size={50} className="social-icon me-3" />
          </a>
        </Col>
        <Col xs="auto">
          <a href="https://g.page/r/CRy-h0yF_84iEAE/review" target="_blank" rel="noopener noreferrer">
            <Google size={50} className="social-icon" />
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default SocialMedia;
