import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Jumbotron from '../Components/Jumbotron';
import OurServices from '../Components/OurServices';
import Testimonials from '../Components/Testamonials';
import BusinessLocationMap from '../Components/BuisnessLocationMap';
import QuoteButton from '../Components/QuoteButton';
import AboutUs from '../Components/AboutUs';
import FeaturedCar from '../Components/Carousel/FeaturedCar';
import SocialMedia from '../Components/SocialMedia';

const HomePage = () => {
  return (
    <div>
      {/* Hero Section */}
      <Jumbotron />

      <Container className="my-5">

        {/* Featured Projects */}
        <Row className="my-5">
          <Col>
            <h2>Featured Projects</h2>
            <FeaturedCar />
          </Col>
        </Row>

        {/* About Us */}
        <AboutUs />

        {/* Social Media Links */}
        <SocialMedia />

        {/* Services */}
        <OurServices />

        {/* Testimonials */}
        <Testimonials />

        {/* Contact Us */}
        {/* <Row className="mb-4">
          <Col>
            <h2>Contact Us</h2>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter your name" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter your email" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Your message" />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row> */}

        {/* Map */}
        <BusinessLocationMap />

        {/* Get a Free Quote */}
        <QuoteButton />

      </Container>
    </div>
  );
}

export default HomePage;