import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';

const BackButton = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/'); // Navigate to the homepage
  };

  return (
    <Button variant="outline-primary" onClick={handleBackClick} className="back-button ms-3">
      <ArrowLeft className="me-1" />
      Back
    </Button>
  );
};

export default BackButton;
