// src/Components/QuoteButton.js
import React, { useState } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import '../Style/QuoteButton.css';

const QuoteButton = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Row>
        <Col className="text-center">
          <Button variant="primary" size="lg" className="mt-4" onClick={handleShow}>
            Get a Free Quote
          </Button>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us for a Free Quote</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p className="contact-info"><strong>Email:</strong> <a href="mailto:carpentrybrosdw@gmail.com">carpentrybrosdw@gmail.com</a></p>
          <p className="contact-info"><strong>Phone:</strong> <a href="tel:+018322446809">+01 832-244-6809</a></p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QuoteButton;
