import React, { useState } from 'react';
import { Carousel, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import feat1 from '../../images/projects/proj8/IMG_3942.jpg';
import feat2 from '../../images/projects/proj13/out6.jpg';
import feat3 from '../../images/projects/proj6/IMG_1598.jpg';
import './Deck1Car.css';

const FeaturedCar = () => {
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = (image) => {
    setSelectedImage(image);
    setShow(true);
  };
// Woodlands fence Job, Deck Job, Flooring
  return (
    <>
      <Carousel className="custom-carousel rounded">
        <Carousel.Item>
          <img
            className="d-block w-100 custom-carousel-img"
            src={feat1}
            alt="First project"
            onClick={() => handleShow(feat1)}
          />
          <Carousel.Caption>
            <h3>Woodlands Fence Installation</h3>
            <p>This 135-foot shadow box-style fence, installed in The Woodlands, features 1.5-inch spacing, double rot boards, trim, and a cedar top cap. The posts were securely set 3 feet deep, as specified by the client, ensuring a successful installation and customer satisfaction</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 custom-carousel-img"
            src={feat2}
            alt="Second project"
            onClick={() => handleShow(feat2)}
          />
          <Carousel.Caption>
            <h3>Upper Deck with Lower Kitchenette</h3>
            <p>This deck replacement project in The Woodlands included composite decking on a roof with a drainage system using EPDM rubber roofing. The design directed water into gutters and featured a fully finished kitchenette with can lights, ceiling fans, and a soffit ceiling</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 custom-carousel-img"
            src={feat3}
            alt="Third project"
            onClick={() => handleShow(feat3)}
          />
          <Carousel.Caption>
            <h3>Tile Installation</h3>
            <p>We installed over 800 square feet of porcelain tile planks, including the necessary jacking up of the exterior wall and reframing of the subfloor to support the added weight of the materials</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <div className="text-end mt-3">
        <Link to="/projects" className="btn btn-primary">
          View More Projects
        </Link>
      </div>

      <Modal show={show} onHide={handleClose} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Full-size Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedImage} alt="Full-size" className="img-fluid" />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default FeaturedCar;
